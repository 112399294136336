.subMenu {
  display: none;
}

.subMenu li {
  line-height: 1.5rem;
}

.primaryMenu {
  background-color: #fff;
}

.primaryMenu .subMenu {
  background-color: #f3f3f3;
  box-shadow: inset 0 1px 2px 0 rgb(0 0 0 / 9%);
}

.menuItem {
  padding: 0 0.5rem;

  a:hover {
    @apply text-blue-700;
  }
}

@media only screen and (min-width: 1024px) {
  .primaryMenu .subMenu {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    background-color: #fff;
    box-shadow: 0 0 7px 0 rgb(0 0 0 / 30%), inset 0 1px 3px 0 transparent;
    min-width: 15rem;
    white-space: nowrap;
    padding: 1rem;
    top: 2.5rem;
    text-align: left;
  }

  .primaryMenu .subMenu::before {
    background-color: #fff;
    box-shadow: -2.5px -2.5px 2px 0 rgb(0 0 0 / 10%), inset 0 1px 3px 0 transparent;
    left: 0;
    margin: 0 auto;
    right: 0;
    content: "";
    height: 0.9285rem;
    position: absolute;
    top: -0.4rem;
    transform: rotate(45deg);
    width: 0.9285rem;
  }

  .menuItem:hover .subMenu {
    display: block;
    z-index: 3;
  }
}

@media only screen and (max-width: 1023px) {
  .mainNav {
    background-color: #e8e8e8;
    height: 100%;
    left: -15.3571rem;
    max-width: 100%;
    position: absolute;
    top: 0;
    width: 15.3571rem;
    z-index: 3;
    font-size: 13px;

    ::-webkit-scrollbar {
      width: 0;
    }

    ::-webkit-scrollbar-track,
    ::-webkit-scrollbar-thumb,
    ::-webkit-scrollbar-thumb:hover {
      background: rgb(241 241 241 / 0%);
    }
  }

  .active .subMenu {
    display: block;
  }

  .isLabel::before {
    border-top: 1px solid #dcdcdc;
    content: "";
    display: block;
    margin: 0.7149rem 1.4285rem 0;
    padding-top: 0.7142rem;
  }

  .primaryMenu a,
  .primaryMenu span {
    display: block;
    padding: 0.5rem;
    text-align: left;
    max-width: fit-content;
  }

  .subMenu li a {
    padding: 0.25rem 1rem !important;
  }

  .primaryMenu a:active,
  .primaryMenu a:focus,
  .primaryMenu a:hover {
    color: #002d87;
    cursor: pointer;
  }

  .primaryMenu .subMenu::before {
    top: 2.75rem;
  }

  .menuItem {
    padding: 0;
  }

  .primaryMenu > .menuItem + .menuItem {
    border-top: 1px solid #dcdcdc;
  }

  .primaryMenu > .menuItem:last-of-type {
    border-bottom: 1px solid #dcdcdc;
  }
}
